import { Power1, Power2 } from 'gsap';

export function animateSectionOne(tl, svgLine, svgCircle, image, title, description) {
  const o = 1.3;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo('.description_column', 0.3, { opacity: 0 }, { opacity: 1 }, 0.3)
    .staggerFromTo(svgLine, 0.5, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerFrom(
      title,
      0.7,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      0.7
    )
    .staggerFrom(
      description,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0
    )
    .staggerFrom(
      image,
      0.8,
      {
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      o
    );
}

export function animateTWoImages(tl, svgLine, svgCircle, imageOne, imageTwo, title, description) {
  const o = 1.3;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo('.description_column', 0.3, { opacity: 0 }, { opacity: 1 }, 0.3)
    .staggerFromTo(svgLine, 0.7, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.7,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .to('.line-horizontal', 0.7, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerFrom(
      title,
      0.5,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      0.5
    )
    .staggerFrom(
      description,
      1.9,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      1.9
    )
    .staggerFromTo(
      imageOne,
      o,
      {
        opacity: 0,
        x: '100px',
        ease: Power1.easeOut,
      },
      {
        opacity: 1,
        x: '-70px',
      },
      0,
      o
    )
    .staggerFromTo(
      imageTwo,
      o,
      {
        opacity: 0,
        x: '-80px',
        ease: Power1.easeOut,
      },
      {
        opacity: 1,
        x: '70px',
      },
      0,
      o
    );
}

export function animateFadeImage(
  tl,
  svgLineOne,
  svgCircleOne,
  svgLineTwo,
  svgCircleTwo,
  image,
  title,
  description,
  imageTwo,
  titleTwo,
  descriptionTwo
) {
  const o = 1.3;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo('.description_column', 0.3, { opacity: 0 }, { opacity: 1 }, 0.3)
    .staggerFromTo(
      svgLineOne,
      0.9,
      { attr: { width: 0 } },
      { attr: { width: 88 }, clearProps: 'all' }
    )
    .staggerFromTo(
      svgCircleOne,
      0.9,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .staggerFromTo(
      svgLineTwo,
      0.9,
      { attr: { width: 0 } },
      { attr: { width: 88 }, clearProps: 'all' }
    )
    .staggerFromTo(
      svgCircleTwo,
      0.9,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerFrom(
      title,
      0.3,
      {
        opacity: 0,
        y: 10,
        ease: Power1.easeOut,
      },
      0,
      0.3
    )
    .staggerFrom(
      description,
      1.1,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      1.1
    )
    .staggerFrom(
      image,
      1.3,
      {
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      1.3
    )
    .staggerFrom(
      titleTwo,
      1.7,
      {
        opacity: 0,
        y: 10,
        ease: Power1.easeOut,
      },
      0,
      1.7
    )
    .staggerFrom(
      descriptionTwo,
      1.9,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      1.9
    )
    .staggerFrom(
      imageTwo,
      o,
      {
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      2.5
    );
}

export function animateBottomImages(tl, svgLine, svgCircle, image, title, description) {
  const o = 1.3;
  return tl
    .staggerFrom(
      title,
      o,
      {
        opacity: 0,
        y: 10,
        ease: Power1.easeOut,
      },
      0,
      0.5
    )
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo('.description_column', 0.3, { opacity: 0 }, { opacity: 1 }, 0.3)
    .staggerFromTo(svgLine, 0.5, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerFrom(
      description,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0
    )
    .staggerFrom(
      image,
      0.8,
      {
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      0.5
    );
}
