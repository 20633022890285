import React, { PureComponent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { TimelineLite } from 'gsap';
import Observer from 'react-intersection-observer';
import { Link, graphql } from 'gatsby';
// import { TimelineMax, Power1, TweenLite } from 'gsap';
import Img from 'gatsby-image';
// import Observer from 'react-intersection-observer';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import LightBlueKey from '../../static/assets/light_blue_key.svg';
import LightBlueKeySecond from '../../static/assets/light_blue_key_second.svg';
import ReadMore from '../../static/assets/Readmore.svg';
import YellowKey from '../../static/assets/yellow_key.svg';
import YellowKeySecond from '../../static/assets/yellow_key_second.svg';
import '../sass/_culture.scss';
import * as globalAnimation from '../animations/globalAnimation';
import {
  animateSectionOne,
  animateTWoImages,
  animateFadeImage,
  animateBottomImages,
} from '../animations/culture';

export default class ourMission extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.animateHeading(this.tl, '.title', '.subtitle');
    globalAnimation.animateBG(this.tl, '.darkblue_bg', 800);
    animateSectionOne(
      this.tl,
      '.svg-line__line_yellow',
      '.svg_circle_yellow',
      '.culture-image1',
      '#culture-title',
      '#culture-description'
    );
  }

  animateSectionTwo = inView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView) {
      globalAnimation.animateBG(tl, '.lightblue_bg', -200);
      animateTWoImages(
        tl,
        '.svg-line__line_lightblue',
        '.svg_circle_lightblue',
        '.culture-image2',
        '.culture-image3',
        '.culture-title2',
        '#culture-description2'
      );
    }
  };

  animateSectionThree = inView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView) {
      animateFadeImage(
        tl,
        '.svg-line__line_yellow_second',
        '.svg_circle_yellow_second',
        '.svg-line__line_lightblue_second',
        '.svg_circle_lightblue_second',
        '.culture-image-4',
        '#culture-title4',
        '#culture-description4',
        '.culture-image-4-two',
        '#culture-title4-two',
        '#culture-description4-two'
      );
    }
  };

  animateSectionFour = inView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView) {
      animateBottomImages(
        tl,
        '.svg-line__line_yellow',
        '.svg_circle_yellow',
        '.culture-image5',
        '#culture-title5',
        '#culture-description5'
      );
    }
  };

  animateSectionFive = inView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView) {
      animateBottomImages(
        tl,
        '.svg-line__line_yellow_second',
        '.svg_circle_yellow_second',
        '.culture-image6',
        '#culture-title6',
        '#culture-description6'
      );
    }
  };

  render() {
    const {
      data: {
        markdownRemark: {
          frontmatter: {
            title,
            sectionOne,
            sectionTwo,
            sectionThree: { leftSection, rightSection },
            sectionFour,
            sectionFive,
          },
        },
      },
    } = this.props;
    return (
      <Layout navbarColor="appcolor">
        <SEO pageTitle="What is the BluePrint culture like?" />
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div className="main_wrapper">
                <div className="wrapper px-0">
                  <div className="page_heading_section text-center">
                    <h3 className="page_heading text-uppercase d-lg-block d-none title">{title}</h3>
                    <h4 className="text-uppercase page_heading_child subtitle">{title}</h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={4} className="culture_image my-3 my-lg-0 order-2 order-lg-1">
              <Img
                className="img-fluid culture-image1"
                fluid={sectionOne.image.childImageSharp.fluid}
              />
            </Col>
            <Col xs={12} lg={8} className="order-1 order-lg-2 mt-3">
              <div className="wrapper-culture py-0">
                <h5 id="culture-title" className="text-bold text-appcolor text-left">
                  {sectionOne.title}
                </h5>
                <div className="position-relative line line-horizontal">
                  <YellowKey />
                </div>
              </div>
              <div className="text-white bg-appcolor-dark darkblue_bg wrapper-culture h-100 d-none d-lg-flex">
                <div className="align-self-center">
                  <p id="culture-description">{sectionOne.descriptionOne}</p>
                  <p id="culture-description" className="mb-0">
                    {sectionOne.descriptionTwo}
                  </p>
                </div>
              </div>
              <div className="d-lg-none d-block">
                <p id="culture-description">{sectionOne.descriptionOne}</p>
                <p id="culture-description" className="mb-0">
                  {sectionOne.descriptionTwo}
                </p>
              </div>
            </Col>
          </Row>

          <Row className="pt-lg-5 mt-lg-5 my-lg-3">
            <Col
              xs={12}
              lg={{ span: 4, offset: 1 }}
              className="position-relative order-2 order-lg-1 mt-lg-5"
            >
              <Observer onChange={this.animateSectionTwo} triggerOnce>
                <div className="d-none d-lg-block">
                  <div className="culture1 align-self-center">
                    <Img
                      className="img-fluid culture-image2"
                      fluid={sectionTwo.leftTranslate.image.childImageSharp.fluid}
                    />
                  </div>
                  <div className="culture1 ml-auto my-5">
                    <Img
                      className="img-fluid culture-image3"
                      fluid={sectionTwo.rightTranslate.image.childImageSharp.fluid}
                    />
                  </div>
                </div>
              </Observer>
              <div className="d-lg-none mb-3">
                <Img
                  fluid={sectionTwo.rightTranslate.image.childImageSharp.fluid}
                  className="img-fluid"
                />
              </div>
              <div className="bg-appcolor-light lightblue_bg culture-bg d-none d-lg-block" />
            </Col>

            <Col
              xs={12}
              // md={{ span: 4, offset: 2 }}
              lg={{ span: 5, offset: 1 }}
              className="order-1 order-lg-2 mt-lg-5"
            >
              <h5 className="text-appcolor text-bold text-left culture-title2">
                {sectionTwo.title}
              </h5>
              <div className="position-relative line line-horizontal">
                <LightBlueKey className="mb-0" />
              </div>
              <p id="culture-description2" className="text-left">
                {sectionTwo.description}
              </p>
            </Col>
          </Row>
        </Container>

        <Container>
          <Observer onChange={this.animateSectionThree} triggerOnce>
            <Row className="my-lg-5 justify-content-between">
              <Col xs={12} lg={5} className="d-flex flex-column justify-content-between">
                <div>
                  <h5 id="culture-title4" className="text-bold text-appcolor text-left">
                    {leftSection.title}
                  </h5>
                  <div className="position-relative line line-horizontal">
                    <YellowKeySecond className="mb-0" />
                  </div>
                  <p id="culture-description4">{leftSection.description}</p>
                </div>
                <div className="mb-3 mb-lg-0">
                  <Img
                    fluid={leftSection.image.childImageSharp.fluid}
                    className="img-fluid culture-image-4"
                  />
                </div>
              </Col>
              <Col xs={12} lg={5}>
                <div className="my-lg-0">
                  <h5 id="culture-title4-two" className="text-bold text-appcolor text-left">
                    {rightSection.title}
                  </h5>
                  <div className="position-relative line line-horizontal">
                    <LightBlueKeySecond className="mb-0" />
                  </div>
                  <p id="culture-description4-two">{rightSection.description}</p>
                </div>
                <div className="mb-3 mb-lg-0">
                  <Img
                    fluid={rightSection.image.childImageSharp.fluid}
                    className="img-fluid culture-image-4-two"
                  />
                </div>
              </Col>
            </Row>
          </Observer>
          <Observer onChange={this.animateSectionFour} triggerOnce>
            <Row className=" my-lg-5 py-lg-5">
              <Col xs={12} lg={5}>
                <div>
                  <h5 id="culture-title5" className="text-bold text-appcolor text-left">
                    {sectionFour.title}
                  </h5>
                  <div className="position-relative line line-horizontal">
                    <YellowKey className="mb-0" />
                  </div>
                  <p id="culture-description5">{sectionFour.description}</p>
                  <div className="d-flex justify-content-start mb-2 mb-lg-3">
                    <div id="culture-description5" className="mx-auto mx-lg-0">
                      <Link
                        to="/innovation/"
                        className="learn_more bordered-btn text-center d-flex"
                      >
                        Learn more{' '}
                        <ReadMore
                          className="arrow-translate mb-0 ml-1 align-self-center"
                          height="100%"
                          width="20px"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} lg={{ span: 6, offset: 1 }}>
                <div className="d-flex justify-content-center justify-content-lg-start align-items-end mt-2 mb-lg-0">
                  <Img
                    fluid={sectionFour.image.childImageSharp.fluid}
                    className="img-fluid h-100 w-100 culture-image5"
                  />
                </div>
              </Col>
            </Row>
          </Observer>
          <Observer onChange={this.animateSectionFive} triggerOnce>
            <Row className="mb-4 my-lg-5 pb-lg-5">
              <Col xs={12} lg={6} className="order-2 order-lg-1">
                <div className="d-flex justify-content-center justify-content-lg-start">
                  <Img
                    fluid={sectionFive.image.childImageSharp.fluid}
                    className="h-100 w-100 img-fluid culture-image6"
                  />
                </div>
              </Col>
              <Col xs={12} lg={{ span: 5, offset: 1 }} className=" order-1 order-lg-2">
                <div className="my-3 my-lg-0 ">
                  <h5 id="culture-title6" className="text-bold text-appcolor text-left">
                    {sectionFive.title}
                  </h5>
                  <div className="position-relative line line-horizontal">
                    <YellowKeySecond className="mb-0" />
                  </div>
                  <p id="culture-description6">{sectionFive.description}</p>

                  <div className="page_heading_section text-center">
                    <div
                      id="culture-description6"
                      className="d-flex flex-column justify-content-start align-items-start"
                    >
                      <div className="py-lg-3 mx-auto mx-lg-0">
                        <Link
                          to="/employee-testimonials/"
                          className="learn_more bordered-btn text-center"
                        >
                          {sectionFive.subtitle}
                          <ReadMore className="arrow-translate mb-0" height="10px" width="40px" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Observer>
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { page: { eq: "what-is-the-bluePrint-culture-like" } }) {
      frontmatter {
        title
        sectionOne {
          title
          descriptionOne
          descriptionTwo
          image {
            childImageSharp {
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        sectionTwo {
          title
          description
          leftTranslate {
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          rightTranslate {
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        sectionThree {
          leftSection {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 720, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          rightSection {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 720, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        sectionFour {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        sectionFive {
          title
          description
          subtitle
          image {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
